import Apiservice from '@/core/services/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;


export default class QuestionBankService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/question-bank`;
    }

    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (data != null && data != undefined && data != "") {
            var queryString = Object.keys(data).map((key) => {
                    return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;

        }
        if (queryString)
            url = url + '&page=' + index;
        else
            url = url + '?page=' + index;
        return apiService.get(url);
    }


    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    allActive() {
        let url = `${this.#api}-active`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}/update`
        return apiService.post(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }
    getByProgram(data) {
        //question-bank/by/user
        let url = `${this.#api}/by/user`
        return apiService.post(url, data);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    async questionsByCategoryAndProgram(id){
        let url = `${this.#api}-category/${id}/questions`
        return await apiService.get(url);
    }

}

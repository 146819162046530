import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class QuizService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/quiz`;
    }

    getQuiz(id) {
        let url = `${this.#api}/${id}/user`;
        return apiService.get(url);
    }

    paginate() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }


    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    getQuestion(id) {
        let url = `${this.#api}/${id}/questions`
        return apiService.get(url);
    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    sort(data) {
        let url = `${this.#api}/sort`;
        return apiService.post(url, data);
    }


    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }
    fetchQuestion(quiz_id,question_code) {
        //http://api.kist.stage/user/quiz/1/question-bank/870016/question
        let url = `${this.#api}/${quiz_id}/question-bank/${question_code}/question`
        return apiService.get(url);
    }
    fetchAllQuestions(quiz_id) {
        //http://api.kist.stage/user/quiz/1/show/questions
        let url = `${this.#api}/${quiz_id}/show/questions`
        return apiService.get(url);
    }
    fetchTimeRemaining(quiz_id,attempt_id) {
        //http://api.kist.stage/user/quiz/1/question-attempt/13/remaining-time
        let url = `${this.#api}/${quiz_id}/question-attempt/${attempt_id}/remaining-time`
        return apiService.get(url);
    }
    submitAnswer(attemptId,data) {
        // http://api.kist.stage/user/question-attempt/13/answer
        let url = `${API_URL}user/student/question-attempt/${attemptId}/answer`
        return apiService.post(url, data);

    }
    completeExam(quiz_id,attempt_id,data) {
        // http://api.kist.stage/user/quiz/1/question-attempt/13/complete
        let url = `${this.#api}/${quiz_id}/question-attempt/${attempt_id}/complete`
        return apiService.post(url, data);

    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    deleteSelectedQuizQuestion(id, questionId) {
        let url = `${this.#api}/${id}/question/${questionId}/delete`
        return apiService.delete(url);
    }

    validatePasscode(userId, quizId, passCode) {
        let url = `${API_URL}user/student/${userId}/quiz/${quizId}/passcode/${passCode}/validate`
        return apiService.get(url);
    }

    setupAndStartQuiz(userId, quizId) {
        let url = `${API_URL}user/student/${userId}/quiz/${quizId}/start`
        return apiService.get(url);
    }

    checkQuizDateAndTime(quizId)
    {
        let url = `${this.#api}/${quizId}/check-date-and-time`
        return apiService.get(url);
    }
    checkRemainingQuestion(quizId=null,attempt_id=null)
    {
        let url = `${this.#api}/${quizId}/check-remaining-question/${attempt_id}`;
        return apiService.get(url);
    }
    showQuizAttemptSummary(quizId=null,attempt_id=null){
        let url = `${this.#api}/${quizId}/quiz-attempt/${attempt_id}/summary`;
        return apiService.get(url);
    }
    completeRemainingQuiz(quizId=null,attempt_id=null,data={}){
        let url = `${this.#api}/${quizId}/complete-remaining/${attempt_id}/quiz-attempt`;
        return apiService.post(url, data);
    }
    //Group Question & Answer
    getLimitedQuestionByQuiz(quizId,data={}){
        let url = `${this.#api}/${quizId}/limited-questions`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    submitGroupAnswer(quizAttemptID,data) {
        let url = `${API_URL}user/student/group-question-attempt/${quizAttemptID}/answer`
        return apiService.post(url, data);

    }


}

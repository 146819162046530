import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import LessonPlannerService from "@/core/services/lesson-planner/lesson-planner/LessonPlannerService";
import ProgramService from "@/core/services/level/program/ProgramService";
import CoursesService from "@/core/services/courses/CoursesService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import TopicService from "@/core/services/lesson-planner/topic/TopicService";
import ActivityService from "@/core/services/lesson-planner/activity/ActivityService";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LessonsService from "@/core/services/lesson-planner/lessons/LessonsService";
import ActivityContentService from "@/core/services/lesson-planner/activity-content/ActivityContentService";
import QuestionBankService from "@/core/services/quiz/question-bank/QuestionBankService";
import QuestionBankCategoryService from "@/core/services/quiz/question-bank/category/QuestionBankCategoryService";
import QuizService from "@/core/services/quiz/QuizService";

const quizService = new QuizService();
const lessonService = new LessonsService();
const activityService = new ActivityService();
const topicService = new TopicService();
const gradeLevelService = new GradeLevelService();
const courseService = new CoursesService();
const programService = new ProgramService();
const lessonPlannerService = new LessonPlannerService();
const academicYearService = new AcademicYearService();
const academicClassService = new AcademicClassService();
const activityContentService = new ActivityContentService();
const questionBankService = new QuestionBankService();
const questionBankCategory = new QuestionBankCategoryService();


export const QuizMixin = {
    data() {
        return {
            search: {
                title: '',
                is_active: ''
            },
            options: [{
                title: null,
                is_correct: 0
            }],
            category_questions: [],
            answerList: [],
            categories: [],
            questions: [],
            programs: [],
            courses: [],
            academic_years: [],
            gradesLevels: [],
            question_bank_types: [
                {title: 'Text', value: 'text'},
                {title: 'File', value: 'file'},
            ],
            types: [
                {title: 'Text', value: 'text'},
                {title: 'File', value: 'file'},
            ],
            exam_question :null,
            time_remain :null,
            question: {
                "question": "",
                "program_ids": "",
                "options": null,
                "answer": null,
                "type": "text",
                "question_category_id": null,
            },
            isBusy: false,
            page: null,
            total: null,
            perPage: null,
            error_msg: null,
            response: null
        }
    },
    mounted() {
        this.getAcademicYears();
        this.getPrograms();
    },
    computed: {
        quiz_id() {
            // this.$store.getters.getQuizId;
            return this.$route.params.quiz_id
        },

        quiz() {
            return this.$store.getters.getQuiz;
        },

        current_user() {
            return this.$store.getters.currentUser;
        },
        no_of_questions() {
            return this.quiz_attempt.no_of_questions;
        },
        // started_time() {
        //     return this.$moment(this.quiz_attempt.started_time).format('YYYY-MM-DD  h:mm:ss a');
        // },
        // should_complete_time() {
        //     return this.$moment(this.started_time).add(this.total_duration, 'minutes');
        // },
        //
        // total_duration(){
        //     return parseInt(this.quiz_attempt.total_time);
        // },
        // time_remaining(){
        //     let time = null ;
        //     setInterval(()=>{
        //            return this.$moment(this.should_complete_time().subtract(1,'seconds'));
        //     })
        //   return  time;
        // },

        exam_questions_codes() {
            return this.quiz_attempt.questions;
        },

        quiz_attempt() {
            return this.$store.getters.getQuizAttempt;
        },


    },

    methods: {
        getQuiz() {
            this.isBusy = true;
            quizService.getQuiz(this.quiz_id).then(response => {
                let quiz = response.data.quiz;
                if (quiz.id)
                    this.$store.dispatch('setQuiz', quiz)
            }).catch(err => {
                this.isBusy = false
            })
        },

       /* fetchQuestion(question_code) {

            this.isBusy = true;
            quizService.fetchQuestion(this.quiz_id,question_code).then(response => {
                this.exam_question = response.data.question;

                // let questions = response.data.quiz;
                // if (quiz.id)
                //     this.$store.dispatch('setQuiz', quiz)
            }).catch(err => {
                this.isBusy = false
            }).finally(()=>{
                this.isBusy = false
            })
        },*/
     /*   fetchAllQuestions() {
            this.isBusy = true;
            quizService.fetchAllQuestions(this.quiz_id).then(response => {
                this.questions = response.data.questions;

                // let questions = response.data.quiz;
                // if (quiz.id)
                //     this.$store.dispatch('setQuiz', quiz)
            }).catch(err => {
                this.isBusy = false
            }).finally(()=>{
                this.isBusy = false
            })
        },*/
       /* fetchTimeRemaining() {
            this.isBusy = true;
            quizService.fetchTimeRemaining(this.quiz_id,this.quiz_attempt.id).then(response => {
                this.time_remain = response.data;

                // let questions = response.data.quiz;
                // if (quiz.id)
                //     this.$store.dispatch('setQuiz', quiz)
            }).catch(err => {
                this.isBusy = false
            }).finally(()=>{
                this.isBusy = false
            })
        },*/
        /*    submitAnswer(data){
            this.isBusy = true;
            quizService
                .submitAnswer(this.quiz_attempt.id,data)
                .then(response => {
                    this.response = response.data
                }).catch(err => {
                this.isBusy = false
            }).finally(()=>{
                this.isBusy = false
            })
        },*/
       /* completeExamRequest(data){
            this.isBusy = true;
            quizService.completeExam(this.quiz_id,this.quiz_attempt.id,data).then(response => {
                this.$snotify.success("Exam Finished !")

            }).catch(err => {
                this.isBusy = false
            }).finally(()=>{
                this.isBusy = false
            })
        },*/
        checkIsPasscodeProtected() {
            if (this.quiz && this.quiz.is_protected && !this.quiz_attempt.id) {
                this.$router.push({name: 'quiz-prepare', paras: {quiz_id: 1}})
            }
            this.isBusy = false
        },

        /*validatePasscode(userId, passcode) {
            quizService.validatePasscode(userId, this.quiz.id, passcode).then(response => {
                if (!response.data.status) {
                    this.error_msg = response.data.msg
                } else {
                    this.setupAndStartQuiz(this.current_user.id, this.quiz.id)
                }
            }).catch(err => {
                this.isBusy = false
            })
        },*/

    /*    setupAndStartQuiz() {
            this.isBusy = true
            let currentUserId = this.current_user.id
            let quizId = this.quiz.id
            quizService.setupAndStartQuiz(currentUserId, quizId).then(response => {
                this.$store.dispatch('setQuizAttempt', response.data.attempt)
                this.$router.push({name: 'quiz-start', params: {quiz_id: quizId}})
            }).catch(err => {
                this.$snotify.error("Please try again later")
                this.isBusy = false
            })
        },*/

        questionBankCategories() {
            questionBankCategory.allActive().then(response => {
                this.categories = response.data
            })
        },
        getPrograms() {
            programService.all().then(response => {
                this.programs = response.data;
            });
        },
        getAcademicYears() {
            academicYearService.all().then(response => {
                this.academic_years = response.data;
            });
        },

        getGrades() {
            gradeLevelService.getByProgram(this.lesson_plan.program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },
        getGradesByProgramId(program_id) {
            gradeLevelService.getByProgram(program_id).then(response => {
                this.gradesLevels = response.data;
            });
        },
        getAllGrades() {
            gradeLevelService.all().then(response => {
                this.gradesLevels = response.data.data;
            });
        },
        getAllCourses() {
            courseService.all().then(response => {
                this.courses = response.data.data;
            });
        },
        getQuizQuestions(id) {
            quizService.getQuestion(id).then(response => {
                this.quiz_questions.length = 0;
                this.quiz_questions = response.data.questions;
            })
        },
        getQuestionsByProgram(program_id, question_bank_category_id) {
            let data = {
                program_id: program_id,
                category_id: question_bank_category_id
            }
            questionBankService.getByProgram(data).then(response => {
                this.questions = response.data.questions;
            })
        },

        // getCourses() {
        //     courseService.getByProgram(this.question_bank.program_id).then(response => {
        //         this.courses = response.data.courses;
        //     });
        // },
        getCourses() {
            let search = {
                academic_year_id: this.question_bank.academic_year_id,
                program_id: this.question_bank.program_id
            }
            this.$bus.emit('toggleLoader',);
            courseService.paginate(search).then(response => {
                this.courses = response.data.data;
                this.$bus.emit('toggleLoader');
            });
        },
        addOption() {
            this.options.push({
                title: null,
                is_correct: 0
            })
        },
        removeOption(index) {
            this.options.splice(index, 1);

        },
        addToCorrectAnswer(index) {
            this.question.answer = null;
            if (this.options[index].is_correct)
                this.answerList.push(this.options[index].title)
            else {
                this.answerList.splice(this.answerList.indexOf(this.options[index].title), 1)
            }
        },

        getQuestions(data, page) {
            questionBankService.paginate(this.search, this.page).then(response => {
                this.question_banks = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
        },

        getQuestion(id) {
            questionBankService.show(id).then(response => {
                this.question = response.data.quiz;
                this.buildForEdit()
            })
        },
        createQuestion(data) {
            questionBankService.store(data).then(response => {
                this.$snotify.success("Created Successfully")
                this.redirectTo()
            }).catch(error => {
                this.isBusy = false
                this.$snotify.error("Unable to process request")
            })
        },
        updateQuestion(id, data) {
            questionBankService.update(id, data).then(response => {
                this.$snotify.success("Updated Successfully")
                this.redirectTo()
            }).catch(error => {
                this.isBusy = false
                this.$snotify.error("Unable to process request")
            })
        },

        deleteQuestionBank(id) {
            questionBankService.delete(id).then(response => {
                this.getQuestions();
                this.$snotify.success("Deleted Successfully")
            })
        },
        deleteQuiz(id) {
            quizService.delete(id).then(response => {
                this.__getQuiz();
                this.$snotify.success("Deleted Successfully")
            })
        },
        __updateQuiz(fd) {
            this.isBusy = true;
            quizService
                .update(this.quiz.id, fd)
                .then(response => {
                    this.isBusy = false;
                    this.$snotify.success("Updated successfully");
                    this.__getQuiz();
                    this.resetForm();
                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    // console.log(error);
                });
        },

        __getQuiz() {
            quizService.paginate().then(response => {
                this.quizes = response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
            })
        },
        __createQuiz(fd) {
            this.isBusy = true;
            quizService
                .store(fd)
                .then((response) => {

                    this.isBusy = false;
                    // this.resetForm();
                    if (response.data.quiz) {
                        this.$router.push({name: 'quiz-update', params: {quiz_id: response.data.quiz.id}})
                    }
                    this.$snotify.success("Created successfully");

                })
                .catch(error => {
                    this.isBusy = false;
                    this.$snotify.error("Something Went Wrong");
                    console.log(error);
                });
        },
        resetForm() {
            this.question = [{
                "question": "",
                "program_ids": "",
                "options": null,
                "answer": null,
                "type": "text",
            }]
        },


        questionsByCategory(programId) {
            questionBankService.questionsByCategoryAndProgram(this.category_id, programId).then(response => {
                this.category_questions = response.data.questions
                this.addAllCategoryQuestionToList()
            }).catch(error => {
                this.$snotify.error("Something went wrong");
                this.isBusy = false;
            })
        },

        deleteSelectedQuizQuestion(quizId, questionId) {
            quizService.deleteSelectedQuizQuestion(quizId, questionId).then(response => {
                this.$snotify.success("Successfully deleted question from quiz")
                this.getQuizQuestions(quizId)
            })
        }
    },
}